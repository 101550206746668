/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBasketItemRequest } from '../models/AddBasketItemRequest';
import type { Basket } from '../models/Basket';
import type { BasketItem } from '../models/BasketItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BasketsService {

    /**
     * Create a basket based on user ID.
     * @returns Basket Created
     * @throws ApiError
     */
    public static postBaskets(): CancelablePromise<Basket> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Baskets',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get a basketItem based on ID.
     * @param basketId
     * @returns Basket Success
     * @throws ApiError
     */
    public static getBaskets(
        basketId: string,
    ): CancelablePromise<Basket> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Baskets/{basketId}',
            path: {
                'basketId': basketId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Add basketItem item.
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public static postBasketsItems(
        requestBody?: AddBasketItemRequest,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Baskets/Items',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Change amount of basket item.
     * @param basketItemId
     * @param amount
     * @returns BasketItem Success
     * @throws ApiError
     */
    public static patchBasketsItems(
        basketItemId: string,
        amount: number,
    ): CancelablePromise<BasketItem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/Baskets/Items/{basketItemId}',
            path: {
                'basketItemId': basketItemId,
            },
            query: {
                'amount': amount,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove basket item.
     * @param basketItemId
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteBasketsItems(
        basketItemId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Baskets/Items/{basketItemId}',
            path: {
                'basketItemId': basketItemId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Empty basket content.
     * @param basketId
     * @returns void
     * @throws ApiError
     */
    public static deleteBasketsItems1(
        basketId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Baskets/{basketId}/Items',
            path: {
                'basketId': basketId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
