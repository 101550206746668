import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {Dialog} from "@headlessui/react";
import {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import { HomeIcon, ShoppingCartIcon } from '@heroicons/react/20/solid'

// @ts-ignore
import classes from './MainNavigation.module.css';
import React from "react";

const navigation = [
    {name: 'Produkt', to: '/produkt'},
    {name: 'Otázky', to: '/otazky'},
    {name: 'Komodity', to: '/komodity'},
    {name: 'O nás', to: '/o-nas'}
]

function MainNavigation() {
    const navigate = useNavigate();
    const [token] = useState(localStorage.getItem('token'));
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('tokenExpiration') !== null) {
            console.log("Session will expire after: " + localStorage.getItem('tokenExpiration'));
            // check if token is expired by comparing current date and tokenExpiration(2023-09-15T01:51:37Z)

            if (new Date() > new Date(localStorage.getItem('tokenExpiration') as string)) {
                navigate("/logout");
                // output time left until token expires
                console.log("Token expired: " + (new Date(localStorage.getItem('tokenExpiration') as string).getTime() - new Date().getTime()) / 1000 + " seconds left");
            }
        }
    }, [navigate]);

    return (<header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <NavLink to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">The Transporters</span>
                        <svg className="h-8 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="-0.007044076919555664 -0.001080244779586792 90.03044128417969 88.31993103027344">
                            <title>Parcium</title>
                            <path
                                d="M55.18 30.71a90.7 90.7 0 0 0-24.92 8A98.72 98.72 0 0 0 9.17 51.48c-.5.41-1.06 1-1.76.58s-.41-1.18-.29-1.82c.62-3.19 1.33-6.36 1.88-9.56.3-1.72 1.47-2.54 2.85-3.2a117.61 117.61 0 0 1 22.62-7.84 151.93 151.93 0 0 1 17.7-3.25c-.26-.54-.77-.27-1.14-.29a173.08 173.08 0 0 0-29.13 1.66 77.79 77.79 0 0 0-7.81 1.6c-2.55.59-2.91.27-2.39-2.41.63-3.27 1.21-6.55 2-9.78.38-1.61-.09-2-1.65-2.11C5.29 14.63 1.18 10.92.14 4.46-.38 1.27.54.12 3.75.11 21.83.1 39.92-.2 58 .23c9.44.23 18.44 2.72 25.37 10a23.63 23.63 0 0 1 6.37 14.07c1.27 11.56-1.65 21.88-10.35 30-6.63 6.16-14.79 9.12-23.66 10.36-5.14.72-10.3.53-15.46.52-1.79 0-2.63.55-3 2.46-1.06 5.77-2.45 11.52-3.56 17.36-.45 2.34-1.65 3.25-4 3.1-1.66-.12-3.33 0-5 0-2 0-2.46-.89-1.89-2.72a104.42 104.42 0 0 1 8.79-19.82C38 54.28 46.22 44.68 55.71 36a28.65 28.65 0 0 0-7.31 4.4C32.23 52.19 20 67.38 10.29 84.75a7.83 7.83 0 0 1-9.21 3.11c-1.23-.51-1.16-1.49-1-2.51 1-4.89 1.94-9.79 2.91-14.68.5-2.53 2.34-4.18 4-5.94a146 146 0 0 1 41-30.6c2.39-1.2 4.8-2.28 7.19-3.42z"
                                fill="#4f46e5">
                            </path>
                        </svg>
                    </NavLink>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>
                <div className={`hidden lg:flex lg:gap-x-12 ${classes.menu}`}>
                    {navigation.map((item) => (
                        <span key={item.name} className="text-sm font-semibold leading-6 text-gray-900">
                            <NavLink to={item.to} className={({isActive}) => isActive ? classes.active : undefined} >
                                {item.name}
                            </NavLink>
                        </span>
                    ))}
                </div>
                {token && (
                    <div className={`hidden lg:flex lg:flex-1 lg:justify-end ${classes.menu}`}>
                        <NavLink to="/dashboard" className={({isActive}) => isActive ? `text-sm font-semibold leading-6 text-gray-900 ` + classes.active : `text-sm font-semibold leading-6 text-gray-900`}>
                            <HomeIcon className="h-4 w-4 inline" /> Dashboard
                        </NavLink> &nbsp; &nbsp; &nbsp;
                        <NavLink to="/basket" className={({isActive}) => isActive ? `text-sm font-semibold leading-6 text-gray-900 ` + classes.active : `text-sm font-semibold leading-6 text-gray-900`}>
                            <ShoppingCartIcon className="h-4 w-4 inline" /> Košík (2)
                        </NavLink> &nbsp; &nbsp; &nbsp;
                        <NavLink to="/logout" className={({isActive}) => isActive ? `text-sm font-semibold leading-6 text-gray-900 ` + classes.active : `text-sm font-semibold leading-6 text-gray-900`}>
                            Odhlásiť sa <span aria-hidden="true">&rarr;</span>
                        </NavLink>
                    </div>
                )}
                {!token && (
                    <div className={`hidden lg:flex lg:flex-1 lg:justify-end ${classes.menu}`}>
                        <NavLink to="/login" className={({isActive}) => isActive ? `text-sm font-semibold leading-6 text-gray-900 ` + classes.active : `text-sm font-semibold leading-6 text-gray-900`}>
                            Prihlásiť sa <span aria-hidden="true">&rarr;</span>
                        </NavLink>
                    </div>
                )}

            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50"/>
                <Dialog.Panel
                    className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <NavLink to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Parcium</span>
                            <svg className="h-8 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="-0.007044076919555664 -0.001080244779586792 90.03044128417969 88.31993103027344">
                                <title>Parcium</title>
                                <path
                                    d="M55.18 30.71a90.7 90.7 0 0 0-24.92 8A98.72 98.72 0 0 0 9.17 51.48c-.5.41-1.06 1-1.76.58s-.41-1.18-.29-1.82c.62-3.19 1.33-6.36 1.88-9.56.3-1.72 1.47-2.54 2.85-3.2a117.61 117.61 0 0 1 22.62-7.84 151.93 151.93 0 0 1 17.7-3.25c-.26-.54-.77-.27-1.14-.29a173.08 173.08 0 0 0-29.13 1.66 77.79 77.79 0 0 0-7.81 1.6c-2.55.59-2.91.27-2.39-2.41.63-3.27 1.21-6.55 2-9.78.38-1.61-.09-2-1.65-2.11C5.29 14.63 1.18 10.92.14 4.46-.38 1.27.54.12 3.75.11 21.83.1 39.92-.2 58 .23c9.44.23 18.44 2.72 25.37 10a23.63 23.63 0 0 1 6.37 14.07c1.27 11.56-1.65 21.88-10.35 30-6.63 6.16-14.79 9.12-23.66 10.36-5.14.72-10.3.53-15.46.52-1.79 0-2.63.55-3 2.46-1.06 5.77-2.45 11.52-3.56 17.36-.45 2.34-1.65 3.25-4 3.1-1.66-.12-3.33 0-5 0-2 0-2.46-.89-1.89-2.72a104.42 104.42 0 0 1 8.79-19.82C38 54.28 46.22 44.68 55.71 36a28.65 28.65 0 0 0-7.31 4.4C32.23 52.19 20 67.38 10.29 84.75a7.83 7.83 0 0 1-9.21 3.11c-1.23-.51-1.16-1.49-1-2.51 1-4.89 1.94-9.79 2.91-14.68.5-2.53 2.34-4.18 4-5.94a146 146 0 0 1 41-30.6c2.39-1.2 4.8-2.28 7.19-3.42z"
                                    fill="#4f46e5">
                                </path>
                            </svg>
                        </NavLink>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Zatvoriť menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className={`-my-6 divide-y divide-gray-500/10 ${classes.menu}`}>
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <span key={item.name} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                        <NavLink to={item.to} className={({isActive}) => isActive ? classes.active : undefined}>
                                            {item.name}
                                        </NavLink>
                                    </span>
                                ))}
                            </div>
                            <div className="py-6">
                                {token && (
                                    <>
                                        <span className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            <NavLink to="/dashboard" className={({isActive}) => isActive ? classes.active : undefined}>
                                                Dashboard
                                            </NavLink>
                                        </span>
                                        <span className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            <NavLink to="/basket" className={({isActive}) => isActive ? classes.active : undefined}>
                                                Košík (2)
                                            </NavLink>
                                        </span>
                                        <span className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            <NavLink to="/logout" className={({isActive}) => isActive ? classes.active : undefined}>
                                                    Odhlásiť sa
                                            </NavLink>
                                        </span>
                                    </>

                                )}
                                {!token && (
                                    <span className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                        <NavLink to="/login" className={({isActive}) => isActive ? classes.active : undefined}>
                                            Prihlásiť sa
                                        </NavLink>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>

    );
}

export default MainNavigation;