import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import classes from "../address/AddressManagementPage.module.css";
import {Business} from "../../tapi";
import formatDate from "../../helpers/DateUtils";
import ApiClient from "../../helpers/ApiClient";

const client = new ApiClient();

function AddressManagementPage() {
    const [businesses, setBusinesses] = React.useState([] as Business[]);



    useEffect(() => {
        client.getBusinessMe().then((resp) => {
            setBusinesses(resp);
        }, (error) => {
            console.log(error);
        });

    }, []);

    return (
        <div className="mx-auto max-w-6xl">
            <div className="text-center mb-20">
                <h1 className="text-4xl tracking-tight text-gray-900 sm:text-4xl">
                    Váš Parcium Business
                </h1>
            </div>

            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                <Link to="/business/create">
                    <div
                        className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.addAddress} ${classes.addressItem}`}>
                        <h2 className="text-gray-800 text-2xl">+</h2>
                        <p className="mt-2 text-gray-600">Zaregistruj si Parcium Business</p>
                    </div>
                </Link>

                {businesses.map((business) => (
                    <Link to={"/business/" + business.id }>
                        <div key={business.id}
                             className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.addAddress} ${classes.addressItem}`}
                             style={{position: "relative"}}
                        >
                            <div className="text-sm font-semibold leading-6 text-gray-900">
                                Názov: {business.name}
                            </div>
                            <div className="text-sm text-gray-600">
                                IČO: {business.ico}
                            </div>
                            <div className="text-sm text-gray-600">
                                Aktívny: {business.active ? "Áno" : "Nie"}
                            </div>
                            <div className="text-sm text-gray-600">
                                Verifikovaný: {business.verified ? "Áno" : "Nie"}
                            </div>
                            <div className="text-sm text-gray-600">
                                Obchod vytvorený dňa: {formatDate(String(business.createdAt))}
                            </div>
                            <div className="text-sm text-gray-600">
                                Adresa: {business.defaultAddressId}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default AddressManagementPage;