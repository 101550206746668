import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import React from "react";
import classes from './Dashboard.module.css';
import {UserDashboard} from "../tapi";
import ApiClient from "../helpers/ApiClient";

const apiClient = new ApiClient();

function DashboardPage() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dashboard, setDashboard] = useState<UserDashboard>({} as UserDashboard);

    useEffect(() => {
        apiClient.getDashboardInfo().then((resp: UserDashboard) => {
            setDashboard(resp);
            }, (error: any) => {
                console.log(error);
            }
        );
    }, []);

    return (
        <div className="mx-auto max-w-6xl">

            <div className="text-center mb-20">
                <h1 className="text-4xl tracking-tight text-gray-900 sm:text-4xl">
                    Dashboard
                </h1>
            </div>
            <div className="grid gap-4 grid-cols-2">
                {/*<Link to="">*/}
                {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
                {/*        <h2 className="text-gray-800 text-2xl">Vaše objednávky</h2>*/}
                {/*        <p className="mt-2 text-gray-600">TODO: Sleduj, vráť, zruš objednávku,*/}
                {/*            stiahni faktúru alebo kúp znovu</p>*/}
                {/*        /!*<LoginSecurity />*!/*/}
                {/*    </div>*/}
                {/*</Link>*/}
                <Link to="/account/security">
                    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>
                        <h2 className="text-gray-800 text-2xl">Prihlasovanie a bezpečnosť</h2>
                        <p className="mt-2 text-gray-600">Zmeniť heslo, email alebo mobilné číslo</p>
                    </div>
                </Link>
                <Link to="/account/address">
                    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>
                        <h2 className="text-gray-800 text-2xl">Vaše adresy</h2>
                        <p className="mt-2 text-gray-600">Upraviť, odstrániť alebo nastaviť predvolenú adresu</p>
                    </div>
                </Link>
                {/*<Link to="">*/}
                {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
                {/*        <h2 className="text-gray-800 text-2xl">Vaše platby</h2>*/}
                {/*        <p className="mt-2 text-gray-600">TODO: Zmeniť alebo pridať platobné údaje a vidieť transakcie</p>*/}
                {/*    </div>*/}
                {/*</Link>*/}
                {/*<Link to="">*/}
                {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
                {/*        <h2 className="text-gray-800 text-2xl">Vaše správy</h2>*/}
                {/*        <p className="mt-2 text-gray-600">TODO: Vidieť alebo odpovedať na správy z Parcia,*/}
                {/*            predávajúcich a kupujúcich</p>*/}
                {/*    </div>*/}
                {/*</Link>*/}
                {/*<Link to="">*/}
                {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
                {/*        <h2 className="text-gray-800 text-2xl">Zákaznícke služby</h2>*/}
                {/*        <p className="mt-2 text-gray-600">TODO: Prezrieť dokumentáciu, možnosti alebo nás kontaktovať</p>*/}
                {/*    </div>*/}
                {/*</Link>*/}
                <Link to="/business">
                    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>
                        <h2 className="text-gray-800 text-2xl">Parcium Business</h2>
                        <p className="mt-2 text-gray-600">Oddelte Vaše firemné nákupy a predaj. Upravte, alebo
                            pridajte svoj podnik. Ponúkajte komodity na predaj alebo vsše transportné služby ostatným
                            účastníkom Parcium trhoviska a zarábajte.</p>
                        {/*<BusinessesManagement />*/}
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default DashboardPage;