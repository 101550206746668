import React  from "react";

export default function BasketTotal() {
    return (
        <>
            <div className="mb-2 flex justify-between">
                <p className="text-gray-700">Materiál</p>
                <p className="text-gray-700">129.99 EUR</p>
            </div>
            <div className="flex justify-between">
                <p className="text-gray-700">Transport</p>
                <p className="text-gray-700">24.99 EUR</p>
            </div>
            <hr className="my-4"/>
            <div className="flex justify-between">
                <p className="text-lg font-bold">Spolu</p>
                <div className="">
                    <p className="mb-1 text-lg font-bold">154.98</p>
                    <p className="text-sm text-gray-700">vrátane DPH</p>
                </div>
            </div>
        </>
    )
}