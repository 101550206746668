import React from "react";
import {Category} from "../tapi";


interface CheckBoxFilterProps {
    onChange: (str: string) => void
    categories: Category[]
}

function CheckBoxFilter(props: CheckBoxFilterProps) {
    // const [categories, setCategories] = React.useState<Category[]>(props.categories as Category[]);

    function changeHandler(event: any) {
        event.preventDefault()
        props.onChange("checkboxFilterChange")
    }

    return (
        <details
            className="overflow-hidden rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden"
        >
            <summary
                className="flex cursor-pointer items-center justify-between gap-2 bg-white p-4 text-gray-900 transition"
            >
                <span className="text-sm font-medium"> Komodity </span>

                <span className="transition group-open:-rotate-180">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="h-4 w-4"
                                    >
                                      <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                    </svg>
                                  </span>
            </summary>

            <div className="border-t border-gray-200 bg-white">
                <header className="flex items-center justify-between p-4">
                    <span className="text-sm text-gray-700"> 0 Selected </span>

                    <button type="button" className="text-sm text-gray-900 underline underline-offset-4">
                        Reset
                    </button>
                </header>

                <ul className="space-y-1 border-t border-gray-200 p-4">
                    {props.categories.map((category) => (
                        <li key={category.id}>
                            <label htmlFor={"FilterCategory_" + category.id}
                                   className="inline-flex items-center gap-2">
                                <input type="checkbox" id={"FilterCategory_" + category.id}
                                       className="h-5 w-5 rounded border-gray-300"/>
                                <span className="text-sm font-medium text-gray-700">{category.name}</span>
                            </label>
                            <ul>
                                {category.subcategories?.map((subcategory) => (
                                    <li key={subcategory.id}>
                                        <label htmlFor={"FilterSubcategory_" + subcategory.id}
                                               className="inline-flex items-center gap-2 p-6">
                                            <input type="checkbox" id={"FilterSubcategory_" + subcategory.id}
                                                   className="h-5 w-5 rounded border-gray-300"
                                                   onChange={changeHandler}
                                            />
                                            <span
                                                className="text-sm font-medium text-gray-700">{subcategory.name}</span>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        ))}
                    {/*    <li>*/}
                    {/*    <label htmlFor="FilterInStock" className="inline-flex items-center gap-2">*/}
                    {/*        <input type="checkbox" id="FilterInStock" className="h-5 w-5 rounded border-gray-300"/>*/}

                    {/*        <span className="text-sm font-medium text-gray-700"> In Stock (5+) </span>*/}
                    {/*    </label>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <label htmlFor="FilterPreOrder" className="inline-flex items-center gap-2">*/}
                    {/*        <input type="checkbox" id="FilterPreOrder" className="h-5 w-5 rounded border-gray-300"/>*/}

                {/*        <span className="text-sm font-medium text-gray-700"> Pre Order (3+) </span>*/}
                {/*    </label>*/}
                {/*</li>*/}

                {/*<li>*/}
                {/*    <label htmlFor="FilterOutOfStock" className="inline-flex items-center gap-2">*/}
                {/*        <input type="checkbox" id="FilterOutOfStock" className="h-5 w-5 rounded border-gray-300"/>*/}

                {/*        <span className="text-sm font-medium text-gray-700"> Out of Stock (10+) </span>*/}
                {/*    </label>*/}
                {/*</li>*/}
                </ul>
            </div>
        </details>
    )

}

export default CheckBoxFilter;