import React from 'react';
import {
    CpuChipIcon, CreditCardIcon, DocumentDuplicateIcon, GlobeEuropeAfricaIcon, HeartIcon, LockClosedIcon,
    UserGroupIcon, ViewfinderCircleIcon
} from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";

export default function ProduktPage() {

    return (
        <div
            className="relative isolate overflow-hidden bg-white mt-6 px-6 py-24 rounded-md sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none"/>
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"/>
                </svg>
            </div>
            <div
                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div
                    className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">
                            <p className="text-base font-semibold leading-7 text-indigo-600">Produkt</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vitajte na
                                našom komoditnom trhu</h1>
                            <p className="mt-6 text-xl leading-8 text-gray-700">
                                V Parciume sme viac než len ďalšia platforma na obchodovanie s komoditami – sme tu, aby
                                sme zmenili spôsob, akým nakupujete a predávate komodity.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                        className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        src="/images/produkt-lumber.jpg"
                        alt="Lumber commodity"
                    />
                </div>
                <div
                    className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                            <p>
                                Naším poslaním je posilniť postavenie jednotlivcov a podnikov v komoditnom priemysle a
                                ponúknuť komplexnú platformu navrhnutú s ohľadom na vaše potreby. Tu sú dôvody, prečo by
                                ste mali s nami spolupracovať:
                            </p>
                            <ul className="mt-8 space-y-8 text-gray-600">
                                <li className="flex gap-x-3">
                                    <DocumentDuplicateIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
                                                           aria-hidden="true"/>
                                    <span>
                                        <strong className="font-semibold text-gray-900">Bezkonkurenčný výber. </strong>
                                        Objavte širokú škálu komodít na dosah ruky. Od poľnohospodárskych produktov až
                                        po energetické zdroje a drahé kovy, poskytujeme prístup k rozmanitej škále
                                        komodít, vďaka čomu nájdete presne to, čo potrebujete.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <CreditCardIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
                                                    aria-hidden="true"/>
                                    <span>
                                        <strong className="font-semibold text-gray-900">Zjednodušené obchodné skúsenosti. </strong>
                                        Zjednodušili sme obchodný proces, aby bol užívateľsky prívetivý pre začiatočníkov
                                        aj skúsených profesionálov. Naše intuitívne rozhranie a pokročilé nástroje robia
                                        nákup a predaj komodít hračkou.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <GlobeEuropeAfricaIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
                                                           aria-hidden="true"/>
                                    <span>
                                        <strong
                                            className="font-semibold text-gray-900">Trhové údaje v reálnom čase. </strong>
                                        Zostaňte informovaní pomocou aktuálnych trhových údajov, trendov a aktualizácií
                                        cien. Naša platforma vás vybaví znalosťami, aby ste mohli robiť informované
                                        obchodné rozhodnutia a využiť príležitosti, keď sa objavia.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
                                                    aria-hidden="true"/>
                                    <span>
                                        <strong className="font-semibold text-gray-900">Bezpečné a transparentné transakcie. </strong>
                                        Bezpečnosť je našou najvyššou prioritou. Zavádzame robustné bezpečnostné
                                        opatrenia na ochranu vašich údajov a transakcií. Navyše naša transparentná
                                        transakčná história zaisťuje dôveru a zodpovednosť pri každej transakcii.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <CpuChipIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    <span>
                                        <strong className="font-semibold text-gray-900">Špičková technológia. </strong>
                                        Využívame najnovšie technológie na zlepšenie vášho zážitku z obchodovania. Naša
                                        platforma je vhodná pre mobilné zariadenia, umožňuje vám obchodovať na cestách a
                                        naše pokročilé algoritmy pomáhajú pri hľadaní najlepších ponúk.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <UserGroupIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
                                                   aria-hidden="true"/>
                                    <span>
                                        <strong
                                            className="font-semibold text-gray-900">Odborná podpora a zdroje. </strong>
                                        Náš tím odborníkov je tu, aby vám pomohol pri každom kroku. Či už ste
                                        v obchodovaní s komoditami nováčik alebo skúsený profesionál, ponúkame
                                        vzdelávacie zdroje, prehľady trhu a zákaznícku podporu, ktoré vám pomôžu.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <ViewfinderCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
                                                          aria-hidden="true"/>
                                    <span>
                                        <strong className="font-semibold text-gray-900">Celoštátny dosah. </strong>
                                        Spojte sa s obchodníkmi a firmami po celom území. Náš trh prekračuje hranice
                                        okresov a otvára nekonečné možnosti pre celoštatny obchod a spoluprácu aj za
                                        hranicami.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <HeartIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    <span>
                                        <strong className="font-semibold text-gray-900">Udržateľnosť a etika. </strong>
                                        Zaviazali sme sa dodržiavať etické a udržateľné obchodné praktiky. Poskytujeme
                                        možnosti pre ekologicky šetrné a zodpovedne získavané komodity, ktoré sú
                                        v súlade s modernými hodnotami a požiadavkami trhu.
                                    </span>
                                </li>
                            </ul>
                            <p className="mt-8">
                                V Parciume nie sme len trhovisko; sme Vaším partnerom v komoditnom priemysle. Či už
                                chcete nakupovať komodity alebo rozšíriť svoje podnikanie celoštátne, sme tu, aby sme to
                                zrealizovali. Pridajte sa k nám ešte dnes a zažite budúcnosť obchodovania s komoditami.
                            </p>
                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Presvedčili sme
                                Vás?</h2>
                            <p className="mt-6">
                                Vytvorte svoje miesto vo svete komodít pomocou Parciuma
                                <Link to="/signup" className="font-semibold text-indigo-600"> tu</Link>.
                            </p>
                            <p className="mt-6"><strong>Objavujte, obchodujte a prosperujte.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}