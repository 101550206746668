import React, {useState} from "react";
import {Link} from "react-router-dom";
import {UpdatePasswordRequest} from "../../tapi";
import ApiClient from "../../helpers/ApiClient";

const apiClient = new ApiClient();

function PasswordUpdatePage() {
    const [error, setError] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [success, setSuccess] = useState(false);

    function passwordUpdateHandler(event: any) {
        event.preventDefault();
        const rq = {
            currentPassword: currentPassword,
            newPassword: password
        } as UpdatePasswordRequest;
        apiClient.sendPasswordUpdate(rq).then(() => {
            setSuccess(true);
            setError("");
        }, (err: any) => {
            console.log(err);
            setSuccess(false);
            setError("Prihlásenie sa nepodarilo, email alebo heslo je nesprávne. Skúste to znovu.");
        });
    }

    function handleCurrentPasswordChange(event: { target: { value: any; }; }) {
        setCurrentPassword(event.target.value);
    }

    function handleNewPasswordChange(event: { target: { value: any; }; }) {
        setPassword(event.target.value);
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Zmeniť svoje heslo
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {success && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">Úspech!</strong><br/>
                            <span className="block sm:inline">Nastavenie nového hesla sa podarilo</span>
                        </div>
                        )}
                    {!success && (
                        <form className="space-y-6" action="#" method="POST" onSubmit={passwordUpdateHandler}>
                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="currentPassword" className="block text-sm font-medium leading-6 text-gray-900">
                                        Aktuálne heslo
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="currentPassword"
                                        name="currentPassword"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={handleCurrentPasswordChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Nové heslo
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={handleNewPasswordChange}
                                    />
                                </div>
                            </div>

                            {error !== "" && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                    <strong className="font-bold">Vyskytla sa chyba!</strong><br/>
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Zmeniť heslo
                                </button>
                            </div>
                        </form>
                    )}


                    <p className="mt-10 text-center text-sm text-gray-500">
                        <Link to="/account/security" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Späť <span aria-hidden="true">→</span>
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );

}

export default PasswordUpdatePage;