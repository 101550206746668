import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";

const apiClient = new ApiClient();
function PhoneNumberUpdatePage() {
    const navigate = useNavigate();
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState("");

    function PhoneNumberUpdateHandler(event: any) {
        event.preventDefault();
        apiClient.sendPhoneNumberUpdate(phoneNumber).then((resp) => {
            setSuccess(true);
            setError("");
            navigate("/account/security");
        }, (err: any) => {
            console.log(err);
            setSuccess(false);
            setError("Zmena emailu sa nezdarila, Telefónne číslo je nesprávne. Skúste to znovu.");
        });
    }

    function handlePhoneNumberChange(event: { target: { value: any; }; }) {
        setPhoneNumber(event.target.value);
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Zmeniť svoje telefónne číslo
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {success && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                             role="alert">
                            <strong className="font-bold">Úspech!</strong><br/>
                            <span className="block sm:inline">Nastavenie nového telefónneho čísla sa podarilo</span>
                        </div>
                    )}
                    {!success && (
                        <form className="space-y-6" action="#" method="POST" onSubmit={PhoneNumberUpdateHandler}>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="phoneNumber"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Nové telefónne číslo
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="tel"
                                        autoComplete="phoneNumber"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={handlePhoneNumberChange}
                                    />
                                </div>
                            </div>

                            {error !== "" && (
                                <div
                                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                                    role="alert">
                                    <strong className="font-bold">Vyskytla sa chyba!</strong><br/>
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Zmeniť telefónne číslo
                                </button>
                            </div>
                        </form>
                    )}


                    <p className="mt-10 text-center text-sm text-gray-500">
                        <Link to="/account/security"
                              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Späť <span aria-hidden="true">→</span>
                        </Link>
                    </p>
                </div>
            </div>
        </>
    )
}

export default PhoneNumberUpdatePage;