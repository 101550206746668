import React, {useState} from 'react';
import {Address, AddressResponse} from "../tapi";

interface AddressFormProps {
    onSubmit: (address: Address) => void
    addressResponse: AddressResponse
}

function AddressForm(props: AddressFormProps) {
    const [address, setAddress] = useState<Address>(props.addressResponse.address as Address);

    function submitHandler(event: any) {
        event.preventDefault()
        const newAddress = {
            ...props.addressResponse.address,
            ...address
        }
        props.onSubmit(newAddress)
    }

    function inputChangeHandler(name: string, value: string) {
        setAddress((prevAddress) => {
            return {
                ...prevAddress,
                [name]: value

            } as Address
        }
    )}

    return (
        <form onSubmit={submitHandler}>
            <div className="mt-6">
                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                    Celé meno (Meno a Priezvisko) *
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        autoComplete="name"
                        onChange={(e) => inputChangeHandler("fullName", e.target.value)}
                        defaultValue={props.addressResponse.address.fullName ? props.addressResponse.address.fullName : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="mt-6">
                <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700">
                    Ulica *
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="addressLine1"
                        id="addressLine1"
                        autoComplete="street-address"
                        onChange={(e) => inputChangeHandler("addressLine1", e.target.value)}
                        defaultValue={props.addressResponse.address.addressLine1 ? props.addressResponse.address.addressLine1 : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="mt-6">
                <label htmlFor="addressLine2" className="block text-sm font-medium text-gray-700">
                    Ulica 2
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="addressLine2"
                        id="addressLine2"
                        autoComplete="street-address"
                        onChange={(e) => inputChangeHandler("addressLine2", e.target.value)}
                        defaultValue={props.addressResponse.address.addressLine2 ? props.addressResponse.address.addressLine2 : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="mt-6">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Mesto/Dedina *
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        onChange={(e) => inputChangeHandler("city", e.target.value)}
                        defaultValue={props.addressResponse.address.city ? props.addressResponse.address.city : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className={"mt-6"}>
                <label htmlFor="postcode" className="block text-sm font-medium text-gray-700">
                    PSČ *
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="postcode"
                        id="postcode"
                        autoComplete="postal-code"
                        onChange={(e) => inputChangeHandler("postcode", e.target.value)}
                        defaultValue={props.addressResponse.address.postcode ? props.addressResponse.address.postcode : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className={"mt-6"}>
                <label htmlFor="county" className="block text-sm font-medium text-gray-700">
                    Kraj (ak je potrebný)
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="county"
                        id="county"
                        autoComplete="county"
                        onChange={(e) => inputChangeHandler("county", e.target.value)}
                        defaultValue={props.addressResponse.address.county ? props.addressResponse.address.county : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className={"mt-6"}>
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Telefón (Môže sa využiť pri doručovaní) *
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        autoComplete="phone"
                        onChange={(e) => inputChangeHandler("phoneNumber", e.target.value)}
                        defaultValue={props.addressResponse.address.phoneNumber ? props.addressResponse.address.phoneNumber : ""}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            {/*<div className={"mt-6"}>*/}
            {/*    <label htmlFor="openingHours" className="block text-sm font-medium text-gray-700">*/}
            {/*        Inštrukcie pre kuriéra, napr. otváracie hodiny*/}
            {/*    </label>*/}
            {/*    <div className="mt-1">*/}
            {/*        <input*/}
            {/*            type="text"*/}
            {/*            name="openingHours"*/}
            {/*            id="openingHours"*/}
            {/*            autoComplete="instructions"*/}
            {/*            onChange={(e) => inputChangeHandler("openingHours", e.target.value)}*/}
            {/*            defaultValue={props.addressResponse.address.openingHours ? props.addressResponse.address.openingHours : ""}*/}
            {/*            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={"mt-3"}>
                <button
                    type="submit"
                    className="w-1/3 rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Uložiť adresu
                </button>
            </div>


        </form>
    )
}

export default AddressForm;