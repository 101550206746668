import React, {useEffect, useState} from 'react';
import AddressForm from "../../components/addressForm";
import {Address, AddressResponse, UpdateAddressRequest} from "../../tapi";
import {useNavigate} from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";

const initialAddressResponse = {address: {} as Address, isDefault: false} as AddressResponse;
const apiClient = new ApiClient();

function EditAddressPage() {
    const navigate = useNavigate();
    const [address, setAddress] = useState<AddressResponse>(initialAddressResponse);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('uuid');
        if (id == null) {
            navigate("/account/address");
        } else {
            apiClient.getAddressesMe().then((resp) => {
                resp.filter((address) => address.address.id === id).map((address) => setAddress(address));
            }, (error) => {
                console.log(error);
            });
        }
    }, [navigate]);


    function editAddressHandler(newAddress: Address) {
        const updateableAddressFields = {
            fullName: newAddress.fullName,
            addressLine1: newAddress.addressLine1,
            addressLine2: newAddress.addressLine2,
            city: newAddress.city,
            postcode: newAddress.postcode,
            county: newAddress.county,
            phoneNumber: newAddress.phoneNumber,
            openingHours: newAddress.openingHours,
        } as UpdateAddressRequest;
        if (newAddress.id == null) {
            return;
        }
        apiClient.updateAddress(newAddress.id, updateableAddressFields).then(() => {
            navigate("/account/address");
        }, (error) => {
            console.log(error);
        });
    }
    
    return (
        <div className="mx-auto max-w-6xl">
            <h2 className="mt-6 text-2xl leading-8 text-gray-600">
                Upraviť adresu
            </h2>
            <AddressForm onSubmit={editAddressHandler} addressResponse={address} />
        </div>
    )
}

export default EditAddressPage;