import React from 'react';

function OrdersPage() {
    return (
        <div>
        <h1>OrdersPage</h1>
        </div>
    )
}

export default OrdersPage;