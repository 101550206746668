import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {CancelablePromise, OpenAPI, User, UserResponse, UsersService} from "../../tapi";

function LoginSecurity() {
    const [user, setUser] = useState<User>({} as User);

    useEffect(() => {

        getUserInfo().then((resp: User) => {
            localStorage.setItem('user', JSON.stringify(resp));
            setUser(resp);
            console.log(resp);
        });

    }, []);

    function getUserInfo(): CancelablePromise<UserResponse> {
        OpenAPI.BASE = "https://tcore.azurewebsites.net";
        OpenAPI.HEADERS = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + localStorage.getItem('token'),
        }
        return UsersService.getUsersMe();
    }

    return (
        <div className="mx-auto max-w-6xl">
            <div className="text-center mb-20">
                <h1 className="text-4xl tracking-tight text-gray-900 sm:text-4xl">
                    Prihlasovanie a bezpečnosť
                </h1>
            </div>

            <div className="grid grid-cols-6 gap-4">
                <div className="col-start-3 col-span-2">
                    <strong>Celé meno:</strong>
                    <div>{user.fullName}</div>
                </div>
                <div>
                    <Link
                        to="/account/security/name-update"
                        className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-3 focus-visible:outline-offset-3 focus-visible:outline-gray-900"
                    >
                        Zmeniť
                    </Link>
                </div>


                <div className="col-start-3 col-span-2">
                    <strong>Emailová adresa:</strong>
                    <div>{user.email}</div>
                </div>
                <div>
                    <Link
                        to="/account/security/email-update"
                        className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-3 focus-visible:outline-offset-3 focus-visible:outline-gray-900"
                    >
                        Zmeniť
                    </Link>
                </div>

                <div className="col-start-3 col-span-2">
                    <strong>Heslo:</strong>
                    <div>********</div>
                </div>
                <div>
                    <Link
                        to="/account/security/password-update"
                        className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-3 focus-visible:outline-offset-3 focus-visible:outline-gray-900"
                    >
                        Zmeniť
                    </Link>
                </div>

                <div className="col-start-3 col-span-2">
                    <strong>Primárne telefónne číslo:</strong>
                    <div>{user.phoneNumber ? user.phoneNumber : "nenastavené"}</div>
                    <p className="text-sm text-gray-500">
                        Na rýchle prihlásenie, ľahšie obnovenie hesla a na prijímanie bezpečnostných upozornení s týmto
                        telefónnym číslom.
                    </p>
                </div>
                <div>
                    <Link
                        to="/account/security/phone-number-update"
                        className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-3 focus-visible:outline-offset-3 focus-visible:outline-gray-900"
                    >
                        Zmeniť
                    </Link>
                </div>

                <div className="col-start-3 col-span-2">
                    <strong>Dvojfaktorové overenie:</strong>
                    <div>{user.twoFactorEnabled ? "Zapnuté" : "Vypnuté"}</div>
                    <div>{user.phoneNumber ? user.phoneNumber : "nenastavené"}</div>
                    <p className="text-sm text-gray-500">
                        Zadávajte kód, ktorý dostanete na svoje telefónne číslo popri prihlasovacom emaile a hesle, aby
                        ste sa prihlásili bezpečnejšie.
                    </p>
                </div>
                <div>
                    <Link
                        to="/account/security/2fa-manage"
                        className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-3 focus-visible:outline-offset-3 focus-visible:outline-gray-900"
                    >
                        Spravovať
                    </Link>
                </div>

                {/*<div className="col-start-3 col-span-2">*/}
                {/*    <strong>Kompromitovaný účet?</strong>*/}
                {/*    <div>Vykonajte kroky, ako je zmena hesla a odhlásenie všade, kde ste prihlásení.</div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Link*/}
                {/*        to="/password-update" onClick={navigateUpdatePasswordHandler}*/}
                {/*        className="rounded-md bg-gray-100 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-3 focus-visible:outline-offset-3 focus-visible:outline-gray-900"*/}
                {/*    >*/}
                {/*        Začnite*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default LoginSecurity;