/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Commodity } from '../models/Commodity';
import type { CreateCommodity } from '../models/CreateCommodity';
import type { OkResult } from '../models/OkResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommodityService {

    /**
     * Get a commodity based on ID.
     * @param commodityId
     * @param withProperties
     * @returns Commodity Success
     * @throws ApiError
     */
    public static getCommodity(
        commodityId: string,
        withProperties: boolean = false,
    ): CancelablePromise<Commodity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Commodity/{commodityId}',
            path: {
                'commodityId': commodityId,
            },
            query: {
                'withProperties': withProperties,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * [DEVELOPER] Delete the commodity
     * @param commodityId
     * @returns OkResult Success
     * @throws ApiError
     */
    public static deleteCommodity(
        commodityId: string,
    ): CancelablePromise<OkResult> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Commodity/{commodityId}',
            path: {
                'commodityId': commodityId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get a list of known commodities.
     * @param categoryId
     * @param subcategoryId
     * @returns Commodity Success
     * @throws ApiError
     */
    public static getCommodity1(
        categoryId?: string,
        subcategoryId?: string,
    ): CancelablePromise<Array<Commodity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Commodity',
            query: {
                'categoryId': categoryId,
                'subcategoryId': subcategoryId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * [DEVELOPER] Create a new commodity
     * @param requestBody
     * @returns Commodity Success
     * @throws ApiError
     */
    public static postCommodity(
        requestBody?: CreateCommodity,
    ): CancelablePromise<Commodity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Commodity',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
