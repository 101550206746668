import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {wait} from "@testing-library/user-event/dist/utils";


function LogOutPage() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        wait(1500).then(r => {
            navigate("/");
            window.location.reload();
        });
    }, [navigate]);

    return (
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Prebieha odhlasovanie
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                    Za malú chvíľku budete odhlásený a presmerovaný na hlavnú stránku
                </p>
            </div>
        </div>
    )
}

export default LogOutPage