import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {Commodity, Subcategory} from "../../tapi";
import ApiClient from "../../helpers/ApiClient";

const apiClient = new ApiClient();

export default function SubcategoryPage() {
    const {subcategoryId } = useParams<{ subcategoryId: string }>();
    const [subcategory, setSubcategory] = React.useState({} as Subcategory);
    const [commodities, setCommodities] = React.useState([] as Commodity[]);

    useEffect(() => {
        apiClient.getSubCategoryById(String(subcategoryId), true).then((subcategory) => {
            setSubcategory(subcategory);
        }, (error) => {
            console.log(error);
        });
        apiClient.getCommodities(undefined, String(subcategoryId)).then((commodities) => {
           setCommodities(commodities);
        }, (error) => {
            console.log(error);
        });
    }, [subcategoryId]);

    return (
        <div className="mx-auto w-full max-w-container
                  bg-white mt-6 px-6 py-24 rounded-md sm:py-32
                  scroll-m-20 sm:scroll-m-24 xl:scroll-m-32">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-none">
                    <div className="font-semibold">
                        {subcategory.name}
                    </div>

                    Filtry:
                    <ul>
                        {subcategory.filters?.map((filter) => (
                            <li key={filter.id} className="mr-2">{filter.name}</li>
                        ))}
                    </ul>


                    <div>
                        <div className="font-semibold">
                            Produkty
                        </div>
                        <ul>
                            {commodities?.map((commodity: Commodity) => (
                                <li key={commodity.id} className="mr-2">
                                    <Link to={"/product/" + commodity.id}>
                                        <div className="mt-2 mb-9 font-semibold">
                                            {commodity.name}
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}