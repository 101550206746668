/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category } from '../models/Category';
import type { CommodityDto } from '../models/CommodityDto';
import type { Filter } from '../models/Filter';
import type { Subcategory } from '../models/Subcategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoryService {

    /**
     * List categories.
     * @param subcategories
     * @returns Category Success
     * @throws ApiError
     */
    public static getCategory(
        subcategories?: boolean,
    ): CancelablePromise<Array<Category>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category',
            query: {
                'subcategories': subcategories,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get a category by ID.
     * @param categoryId
     * @param subcategories
     * @returns Category Success
     * @throws ApiError
     */
    public static getCategory1(
        categoryId: string,
        subcategories?: boolean,
    ): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category/{categoryId}',
            path: {
                'categoryId': categoryId,
            },
            query: {
                'subcategories': subcategories,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List subcategories by category ID.
     * @param categoryId
     * @returns Category Success
     * @throws ApiError
     */
    public static getCategorySubcategory(
        categoryId: string,
    ): CancelablePromise<Array<Category>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category/{categoryId}/Subcategory',
            path: {
                'categoryId': categoryId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get a subcategory by ID.
     * @param subcategoryId
     * @param filters
     * @returns Subcategory Success
     * @throws ApiError
     */
    public static getCategorySubcategory1(
        subcategoryId: string,
        filters?: boolean,
    ): CancelablePromise<Subcategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category/Subcategory/{subcategoryId}',
            path: {
                'subcategoryId': subcategoryId,
            },
            query: {
                'filters': filters,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List filters by subcategory ID.
     * @param subcategoryId
     * @returns Filter Success
     * @throws ApiError
     */
    public static getCategorySubcategoryFilters(
        subcategoryId: string,
    ): CancelablePromise<Array<Filter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category/Subcategory/{subcategoryId}/Filters',
            path: {
                'subcategoryId': subcategoryId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List commodity by subcategory ID.
     * @param subcategoryId
     * @returns Filter Success
     * @throws ApiError
     */
    public static getCategorySubcategoryCommodity(
        subcategoryId: string,
    ): CancelablePromise<Array<Filter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category/Subcategory/{subcategoryId}/Commodity',
            path: {
                'subcategoryId': subcategoryId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param categoryName
     * @returns CommodityDto Success
     * @throws ApiError
     */
    public static getCategoryProductsWithProperties(
        categoryName: string,
    ): CancelablePromise<Array<CommodityDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Category/{categoryName}/products-with-properties',
            path: {
                'categoryName': categoryName,
            },
        });
    }

}
