import React from 'react';

function ServicesManagementPage() {
    return (
        <div>
            <h1>Services Management Page</h1>
        </div>
    )
}

export default ServicesManagementPage;