import React, {useEffect} from 'react';
import {Category} from "../../tapi";
import ApiClient from "../../helpers/ApiClient";
import {useParams} from "react-router-dom";

const apiClient = new ApiClient();
export default function SubcategoriesPage() {
    const { categoryId } = useParams<{ categoryId: string }>();
    const [category, setCategory] = React.useState({} as Category);

    useEffect(() => {
        apiClient.getCategory(String(categoryId), true).then((category) => {
            setCategory(category);
        }, (error) => {
            console.log(error);
        });
    }, [categoryId]);

    return (
        <div className="mx-auto w-full max-w-container
                  bg-white mt-6 px-6 py-24 rounded-md sm:py-32
                  scroll-m-20 sm:scroll-m-24 xl:scroll-m-32">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-none">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900">Komodita {category.name}</h2>

                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                        {category.subcategories?.map((subcategory) => (
                            <div key={subcategory.id} className="group relative">
                                <div className="mt-4 flex justify-between">
                                    <div>
                                        <h3 className="text-sm text-gray-700">
                                            <a href={"/komodity/" + categoryId + "/" + subcategory.id}>
                                                <span aria-hidden="true" className="absolute inset-0" />
                                                {subcategory.name}
                                            </a>
                                        </h3>
                                    </div>
                                    <div className="text-sm font-medium text-gray-900">
                                        {subcategory.filters?.map((filter) => (
                                            <div key={filter.id} className="mr-2">{filter.name}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}